import React, { Component } from 'react'

import Header from '../header/Header'
import { toast } from 'react-toastify'
import api from '../../utils/api'
import { validNumberRegex } from '../../utils/validations'
import DatePicker from 'react-datepicker'
import { getYear, validEmailRegex } from '../../utils/validations'
import { Typeahead } from 'react-bootstrap-typeahead'

export default class WorkStatusForm extends Component {

    constructor(props) {
    super(props)
    if (this.props.location.state) {
      this.state = {
        ...this.props.location.state,
        isUpdateRequest: true,
        districtOption: [],
        vidhanSabhaOption: [],
        panchayatOption: [],
        isDisabled: false,
        errors: {
          name: '',
          mobileNumber: '',
          whatsappNumber: '',
          district: '',
          panchayat: '',
          vidhanSabha: '',
          remarks: '',
            followUp1: '',
            followUp2: '',
            reference: '',
            status: '',
            callOnDate: '',
            workDetails: '',
          responsibility: ''
        },
      }
    } else {
      this.state = {
         name: '',
          mobileNumber: '',
          whatsappNumber: '',
          district: '',
          panchayat: '',
          vidhanSabha: '',
          remarks: '',
            followUp1: '',
            followUp2: '',
            reference: '',
            status: '',
            callOnDate: '',
            workDetails: '',
          responsibility: '',
        isUpdateRequest: false,
        districtOption: [],
        vidhanSabhaOption: [],
        panchayatOption: [],
        isDisabled: false,
        errors: {
           name: '',
          mobileNumber: '',
          whatsappNumber: '',
          district: '',
          panchayat: '',
          vidhanSabha: '',
          remarks: '',
            followUp1: '',
            followUp2: '',
            reference: '',
            status: '',
            callOnDate: '',
            workDetails: '',
          responsibility: ''
        },
      }
    }
  }

  handleChange = (event) => {
    event.preventDefault()
    const { name, value } = event.target
    let errors = this.state.errors

      
    switch (name) {
      case 'name':
        errors.name = value.length === 0 ? 'This is a required field.' : ''
        break
      case 'followUp1':
        errors.followUp1 = value.length === 0 ? 'This is a required field.' : ''
            break
         case 'followUp2':
        errors.followUp2 = value.length === 0 ? 'This is a required field.' : ''
            break
        case 'callOnDate':
        errors.callOnDate = value.length === 0 ? 'This is a required field.' : ''
        break
      case 'status':
        errors.status = value.length === 0 ? 'This is a required field.' : ''
        break
      case 'district':
        errors.district = value.length === 0 ? 'This is a required field.' : ''
        break
      case 'panchayat':
        errors.panchayat = value.length === 0 ? 'This is a required field.' : ''
        break
      case 'vidhanSabha':
        errors.vidhanSabha =
          value.length === 0 ? 'This is a required field.' : ''
        break
      case 'reference':
        errors.reference = value.length === 0 ? 'This is a required field.' : ''
        break
      case 'mobileNumber':
        errors.mobileNumber =
          value.length === 0 ? 'This is a required field.' : ''
        break
      case 'whatsappNumber':
        errors.whatsappNumber =
          value.length === 0 ? 'This is a required field.' : ''
        break
      case 'responsibility':
        errors.responsibility = value.length === 249 ? 'Max 250 characters' : ''
        break
      case 'remarks':
        errors.remarks = value.length === 499 ? 'Max 500 characters' : ''
        break
      default:
        break
    }

    this.setState({ errors })
  }

  componentDidMount = () => {
    this.fetchConstituency()
    if (this.state.isUpdateRequest) {
      this.makeVidhanSabha()
        this.makePanchayat()
    }
    }
    
    fetchConstituency = async () => {
    await api
      .getConstituency({
        type: 'Zila Level',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error('No Disrict found. Please make Disrict first.', {
            autoClose: 1250,
            closeButton: false,
          })
        } else {
          toast.success('District Fetched sucessfully.', {
            autoClose: 1250,
            closeButton: false,
          })
          localStorage.setItem(
            'District',
            JSON.stringify(response.data.data.rows)
          )
          this.makeDistrict()
        }
      })
    await api
      .getConstituency({
        type: 'Vidhan Sabha',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Vidhan Sabha found. Please make Vidhan Sabha first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          toast.success('Vidhan Sabha Fetched sucessfully.', {
            autoClose: 1250,
            closeButton: false,
          })
          localStorage.setItem(
            'Vidhan Sabha',
            JSON.stringify(response.data.data.rows)
          )
        }
      })
    await api
      .getConstituency({
        type: 'Gram Panchayat',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error('No Panchayat found. Please make Panchayat first.', {
            autoClose: 1250,
            closeButton: false,
          })
        } else {
          toast.success('Panchayat Fetched sucessfully.', {
            autoClose: 1250,
            closeButton: false,
          })
          localStorage.setItem(
            'Panchayat',
            JSON.stringify(response.data.data.rows)
          )
        }
      })
  }

  makeDistrict = () => {
    const district = JSON.parse(localStorage.getItem('District'))
    let temp = []
    district.map((data) => {
      temp.push(data.meta.name)
    })
    this.setState({
      districtOption: [...temp],
    })
  }

  makeVidhanSabha = () => {
    const vidhanSabha = JSON.parse(localStorage.getItem('Vidhan Sabha'))
    let temp = []
    vidhanSabha.map((data) => {
      if (data.meta.district === this.state.district) {
        temp.push(data.meta.name)
      }
    })
    if (temp.length === 0) {
      toast.error(
        'No Vidhan Sabha for this district. Please make Vidhan sabha.',
        {
          autoClose: 1500,
          closeButton: false,
        }
      )
    } else {
      this.setState({
        vidhanSabhaOption: [...temp],
      })
    }
  }

  makePanchayat = () => {
    const Panchayat = JSON.parse(localStorage.getItem('Panchayat'))
    let temp = []
    Panchayat.map((data) => {
      if (data.meta.vidhanSabha === this.state.vidhanSabha) {
        temp.push(data.meta.name)
      }
    })
    if (temp.length === 0) {
      toast.error(
        'No Panchayat for this vidhan sabha. Please make Panchayat.',
        {
          autoClose: 1500,
          closeButton: false,
        }
      )
    } else {
      this.setState({
        panchayatOption: [...temp],
      })
    }
  }

  makeLetterCapital = (event) => {
    event.preventDefault()
    let { district, vidhanSabha, panchayat } = { ...this.state }
    if (district.charAt(0) !== district.charAt(0).toUpperCase()) {
      district = district.charAt(0).toUpperCase() + district.slice(1)
    }

    if (vidhanSabha.charAt(0) !== vidhanSabha.charAt(0).toUpperCase()) {
      vidhanSabha = vidhanSabha.charAt(0).toUpperCase() + vidhanSabha.slice(1)
    }
    if (panchayat.charAt(0) !== panchayat.charAt(0).toUpperCase()) {
      panchayat = panchayat.charAt(0).toUpperCase() + panchayat.slice(1)
    }

    this.setState(
      {
        district: district,
        vidhanSabha: vidhanSabha,
        panchayat: panchayat,
        isDisabled: true,
      },
      (event) => {
        if (this.state.isUpdateRequest) {
          this.handleVisitorUpdate()
        } else {
          this.handleAddVisitor()
        }
      }
    )
  }

    render() {
      
        const { isUpdateRequest } = this.state

    return (
      <div className="NewClientForm">
        <Header />
        <div className="frame">
          <div className="FormOuterFrame">
            <div className="DivHeading">
              <p className="TxtHeading">Work Status</p>
            </div>
            <div className="FormFrame">
                        <form onSubmit={this.makeLetterCapital}>
                            
                            <div className="TxtInputFrame">
                  <p className="TxtInput">District</p>
                 <p className="TxtStar">*</p>
                </div>
                
                  <Typeahead
                    id="district"
                    labelKey="district"
                    placeholder="Please select a District"
                    required
                    onChange={(district) => {
                      this.setState({ district: district[0] }, () =>
                        this.makeVidhanSabha()
                      )
                    }}
                    options={this.state.districtOption}
                    defaultInputValue={this.state.district}
                    style={{ marginBottom: '20px' }}
                  />
               <span className="validation-error-message">{this.state.errors.district}</span>

               

                <div className="TxtInputFrame">
                  <p className="TxtInput">Vidhan Sabha</p>
                  <p className="TxtStar">*</p>
                            </div>
                            <Typeahead
                    id="vidhanSabha"
                    labelKey="vidhanSabha"
                    placeholder="Please select a Vidhan Sabha"
                    onChange={(vidhanSabha) => {
                        this.setState({ vidhanSabha: vidhanSabha[0] }, () => { 
                            if (vidhanSabha[0] !== '') {
                                this.makePanchayat()
                          }
                        }
                        
                      )
                    }}
                    options={this.state.vidhanSabhaOption}
                    defaultInputValue={this.state.vidhanSabha}
                    style={{ marginBottom: '20px' }}
                  />
                 <span className="validation-error-message">{this.state.errors.vidhanSabha}</span> 

                

               

                <div className="TxtInputFrame">
                  <p className="TxtInput">Panchayat</p>
                  <p className="TxtStar">*</p>
                </div>
                <Typeahead
                    id="panchayat"
                    labelKey="panchayat"
                    placeholder="Please select a Panchayat"
                    required
                    onChange={(panchayat) => {
                      this.setState({ panchayat: panchayat[0] })
                    }}
                    options={this.state.panchayatOption}
                    defaultInputValue={this.state.panchayat}
                    style={{ marginBottom: '20px' }}
                  />
                            <span className="validation-error-message">{this.state.errors.panchayat}</span>
                            
                            <div className="TxtInputFrame">
                  <p className="TxtInput">Work Details</p>
                  <p className="TxtStar">*</p>
                            </div>
                            
                            <input
                  type="text"
                  id="name"
                  name="name"
                  className="InputFrame"
                  placeholder="Please enter work details"
                  required
                  value={this.state.name}
                  onChange={(e) => {
                    this.handleChange(e)
                    this.setState({ workDetails: e.target.value })
                  }}
                />
                <span className="validation-error-message">
                  {this.state.errors.workDetails}
                </span>

                <div className="TxtInputFrame">
                  <p className="TxtInput">Name</p>
                  <p className="TxtStar">*</p>
                            </div>
                            
                            <input
                  type="text"
                  id="name"
                  name="name"
                  className="InputFrame"
                  placeholder="Please enter name"
                  required
                  value={this.state.name}
                  onChange={(e) => {
                    this.handleChange(e)
                    this.setState({ name: e.target.value })
                  }}
                />
                <span className="validation-error-message">
                  {this.state.errors.name}
                            </span>
                            
                            <div className="TxtInputFrame">
                  <p className="TxtInput">Mobile Number</p>
                  <p className="TxtStar">*</p>
                </div>
                <input
                  type="text"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="InputFrame"
                  placeholder="Please enter mobile number"
                  required
                  value={this.state.mobileNumber}
                  onChange={(e) => {
                    if (
                      (validNumberRegex.test(e.target.value) ||
                        e.target.value === '') &&
                      e.target.value.length <= 10
                    ) {
                      this.handleChange(e)
                      this.setState({ mobileNumber: e.target.value })
                    }
                  }}
                />
                <span className="validation-error-message">
                  {this.state.errors.mobileNumber}
                </span>

                <div className="TxtInputFrame">
                  <p className="TxtInput">Whatsapp Number</p>
                  <p className="TxtStar">*</p>
                </div>
                <input
                  type="text"
                  id="whatsappNumber"
                  name="whatsappNumber"
                  className="InputFrame"
                  placeholder="Please enter whatsapp number"
                  required
                  value={this.state.whatsappNumber}
                  onChange={(e) => {
                    if (
                      (validNumberRegex.test(e.target.value) ||
                        e.target.value === '') &&
                      e.target.value.length <= 10
                    ) {
                      this.handleChange(e)
                      this.setState({ whatsappNumber: e.target.value })
                    }
                  }}
                />
                <span className="validation-error-message">
                  {this.state.errors.whatsappNumber}
                            </span>
                            
                           

                <div className="TxtInputFrame">
                  <p className="TxtInput">Reference</p>
                  <p className="TxtStar">*</p>
                </div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="InputFrame"
                  placeholder="Please enter reference name"
                  required
                  value={this.state.name}
                  onChange={(e) => {
                    this.handleChange(e)
                    this.setState({ reference: e.target.value })
                  }}
                />
                <span className="validation-error-message">
                  {this.state.errors.reference}
                </span>

                <div className="TxtInputFrame">
                  <p className="TxtInput">Follow up 1</p>
                  <p className="TxtStar">*</p>
                </div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholder="Pick from calendar view"
                  className="InputFrame"
                  selected={
                    this.state.followUp1 !== '' ? new Date(this.state.followUp1) : ''
                  }
                  onChange={(date) => {
                    let errors = this.state.errors
                    if (getYear(date).toString().length === 4) {
                      errors.followUp1 = ''
                      this.setState({ followUp1: date, errors })
                    } else {
                      errors.followUp1 = 'Invalid Year'
                      this.setState({ errors })
                    }
                  }}
                            />
                            
                             <span className="validation-error-message">
                  {this.state.errors.followUp1}
                            </span>
                            
                            <div className="TxtInputFrame">
                  <p className="TxtInput">Follow up 2</p>
                  <p className="TxtStar">*</p>
                </div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholder="Pick from calendar view"
                  className="InputFrame"
                  selected={
                    this.state.followUp2 !== '' ? new Date(this.state.followUp2) : ''
                  }
                  onChange={(date) => {
                    let errors = this.state.errors
                    if (getYear(date).toString().length === 4) {
                      errors.followUp2 = ''
                      this.setState({ followUp2: date, errors })
                    } else {
                      errors.followUp1 = 'Invalid Year'
                      this.setState({ errors })
                    }
                  }}
                            />
                            
                             <span className="validation-error-message">
                  {this.state.errors.followUp2}
                            </span>
                            
                            <div className="TxtInputFrame">
                  <p className="TxtInput">Call on date</p>
                  <p className="TxtStar">*</p>
                </div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholder="Pick from calendar view"
                  className="InputFrame"
                  selected={
                    this.state.callOnDate !== '' ? new Date(this.state.callOnDate) : ''
                  }
                  onChange={(date) => {
                    let errors = this.state.errors
                    if (getYear(date).toString().length === 4) {
                      errors.callOnDate = ''
                      this.setState({ callOnDate: date, errors })
                    } else {
                      errors.callOnDate = 'Invalid Year'
                      this.setState({ errors })
                    }
                  }}
                            />
                            
                             <span className="validation-error-message">
                  {this.state.errors.callOnDate}
                            </span>
                            
                            <div className="TxtInputFrame">
                  <p className="TxtInput">Status</p>
                  <p className="TxtStar">*</p>
                </div>
                <div style={{ marginBottom: '30px' }}>
                  <div className="SelectRadio">
                    <label
                      className="radiobutton"
                      onClick={() => this.setState({ status: 'VERYIMPORTANT' })}
                    >
                      <span
                        className={
                          this.state.status === 'VERYIMPORTANT'
                            ? 'checked'
                            : 'unchecked'
                        }
                      />
                    </label>
                    <p className="TxtRadioInput">Very Important</p>
                  </div>
                  <div className="SelectRadio">
                    <label
                      className="radiobutton"
                      onClick={() => this.setState({ status: 'IMPORTANT' })}
                    >
                      <span
                        className={
                          this.state.status === 'IMPORTANT'
                            ? 'checked'
                            : 'unchecked'
                        }
                      />
                    </label>
                    <p className="TxtRadioInput">Important</p>
                  </div>
                  <div className="SelectRadio">
                    <label
                      className="radiobutton"
                      onClick={() => this.setState({ status: 'GENERAL' })}
                    >
                      <span
                        className={
                          this.state.status === 'GENERAL'
                            ? 'checked'
                            : 'unchecked'
                        }
                      />
                    </label>
                    <p className="TxtRadioInput">General</p>
                  </div>
                  
                </div>
                            
                            <div className="TxtInputFrame">
                                <p className="TxtInput">Responsibility</p>
                               
                </div>
                <textarea
                  type="text"
                  id="responsibility"
                  name="responsibility"
                  className="InputFrame"
                  placeholder="Enter your responsibility. Max 500 characters."
                  rows="10"
                  maxLength="500"
                  value={this.state.remarks}
                  onChange={(e) => {
                    this.handleChange(e)
                    this.setState({ responsibility: e.target.value })
                  }}
                            />
                            
                            

                <div className="TxtInputFrame">
                                <p className="TxtInput">Remarks</p>
                                 
                </div>
                <textarea
                  type="text"
                  id="remarks"
                  name="remarks"
                  className="InputFrame"
                  placeholder="Enter Reamarks. Max 500 characters."
                  rows="10"
                  maxLength="500"
                  value={this.state.remarks}
                  onChange={(e) => {
                    this.handleChange(e)
                    this.setState({ remarks: e.target.value })
                  }}
                            />
                             

                {isUpdateRequest === false ? (
                  <button
                    type="submit"
                    className="BtnSubmit"
                    style={
                      this.state.isDisabled ? { pointerEvents: 'none' } : null
                    }
                  >
                    Submit
                  </button>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '40px',
                    }}
                  >
                    <button
                      type="button"
                      className="PrintBtn UpdateButton"
                      onClick={(event) => {
                        this.setState({ isDisabled: true })
                        this.makeLetterCapital(event)
                      }}
                      style={
                        this.state.isDisabled ? { pointerEvents: 'none' } : null
                      }
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
